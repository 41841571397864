// Lib imports
import axios from 'axios';
import { url } from '@/config/api';
import router from '@/router';
import store from '@/store';

axios.defaults.baseURL = url();
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = !(process.env.NODE_ENV === 'production');
const HTTP = axios.create({});
const HTTPLogin = axios.create({});

HTTP.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

HTTP.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    store.dispatch('auth/NETWORK_ERROR', false);
    if (response.headers['new-token']) {
      store.dispatch('auth/REFRESH_TOKEN', response.headers['new-token']);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.headers['New-Token']) {
      store.dispatch('auth/REFRESH_TOKEN', error.response.headers['new-token']);
    }
    if (error.message === 'Network Error') {
      store.dispatch('auth/NETWORK_ERROR', true);
      return Promise.reject(error);
    }
    if (error.response) {
      if (error.response.status === 400 || error.response.status === 401) {
        store.dispatch('auth/LOGOUT');
        router.push({ path: '/login' });
      }
      if (error.response.status === 403) {
        router.push({ path: '/' });
      }
    }
    return Promise.reject(error);
  }
);

export { HTTP, HTTPLogin };
